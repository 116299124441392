<template>
  <div>
    <TButton
      content="Watch boxes was not in container"
      @click="openModalShowBoxNotInContainer"
      class="btn-danger btn-sm btn-ghost-danger"
    />

    <TModal
      title="Watch boxes was not in container"
      size="lg"
      :show.sync="show"
      :fetching="loading"
    >
      <template #actions>
        <TButtonSave
          @click="onExportCSV"
          :options="{ content: 'Export CSV' }"
          v-if="!!data_table.length"
        />
        <TButton
          content="Remove all"
          @click="onRemoveAll"
          :disabled="loading"
          class="btn-danger"
        />
      </template>

      <TTableSimple :fields="referenceFields" :items="data_table" noResult>
        <template #_="{ item }">
          <td>
            <b
              class="text-success"
              style="font-size: 12px"
              v-if="item.pivot_lading_bills_with_container_count == 1"
              >1 {{$t("Lading bill")}}</b
            >
            <b class="text-danger" style="font-size: 12px" v-else
              >{{ item.pivot_lading_bills_with_container_count }} {{$t("Lading bill")}}</b
            >
          </td>
        </template>
        <template #box_id="{ item }">
          <td>
            <b>{{ item.box_id }}</b>
          </td>
        </template>
        <template #lading_bill_id="{ item }">
          <td>
            <p>{{ item.lading_bill_id }}</p>
          </td>
        </template>
        <template #quantity="{ item }">
          <td>
            <TMessageNumber
              :value="item.quantity"
              :editable="item.pivot_lading_bills_with_container_count > 1"
              @change="(q) => onChangeQuantity(q, item)"
            />
            <div
              v-if="item.pivot_lading_bills_with_container_count > 1"
              style="font-size: 10px"
              class="d-flex align-items-center"
            >
              <TMessage content="Quantity in lading bill" />
              <b class="ml-1">{{ item.box_lading_bill_quantity }}</b>
            </div>
          </td>
        </template>
        <template #updated_at="{ item }">
          <td>
            <TMessageDateTime :content="item.updated_at" />
          </td>
        </template>
      </TTableSimple>
    </TModal>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      loading: false,
      not_in_container_boxes: [],
      show: false,
      referenceFields: [
        {
          key: "_",
          label: "#",
        },
        {
          key: "box_id",
          label: "SKU",
        },
        {
          key: "lading_bill_id",
          label: "Lading bill",
        },
        {
          key: "order_id",
          label: "Order",
        },
        {
          key: "customer_name",
          label: "Customer",
        },
        {
          key: "quantity",
          label: "Quantity not in container",
        },
        {
          key: "updated_at",
          label: "Updated at",
        },
      ],
    };
  },
  computed: {
    invoice_id() {
      return this.$route.query.invoice_id;
    },
    data_table() {
      return this.not_in_container_boxes;
    },
  },
  watch: {
    invoice_id: {
      handler() {
        this.not_in_container_boxes = [];
      },
    },
  },
  methods: {
    openModalShowBoxNotInContainer() {
      this.show = true;

      this.onRefresh();
    },
    onRefresh() {
      this.loading = true;

      this.$tomoni.warehouse.containers
        .getUndesiredBoxContainer(this.invoice_id)
        .then((res) => {
          this.not_in_container_boxes = res.data.reduce((p, c) => {
            c.pivot_lading_bills.forEach((pivot_lb) => {
              p.push({
                pivot_lading_bills_with_container_count:
                  c.pivot_lading_bills_with_container_count,
                quantity: c.quantity_in_invoice - c.quantity_in_container,
                box_lading_bill_quantity: pivot_lb.quantity,
                lading_bill_id: pivot_lb.lading_bill_id,
                box_lading_bill_id: pivot_lb.id,
                order_id: pivot_lb.order_id,
                customer_name: pivot_lb?.customer?.name,
                box_id: c.id,
                updated_at: c.updated_at,
                removing: false,
              });
            });

            return p;
          }, []);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onRemoveAll() {
      this.loading = true;

      let params = {};

      let box_with_multiple_lb_grouped_obj = this.lodash.groupBy(
        this.not_in_container_boxes.filter(
          (i) => i.pivot_lading_bills_with_container_count > 1
        ),
        "box_id"
      );

      Object.entries(box_with_multiple_lb_grouped_obj).forEach(
        ([box_id, rows], idx) => {
          params[`boxes[${idx}][id]`] = box_id;

          rows.forEach((row, row_idx) => {
            params[`boxes[${idx}][box_lading_bills][${row_idx}][id]`] =
              row.box_lading_bill_id;
            params[`boxes[${idx}][box_lading_bills][${row_idx}][quantity]`] =
              row.quantity;
          });
        }
      );

      this.$tomoni.warehouse.containers
        .removeUndesiredBoxContainer(this.invoice_id, params)
        .then(() => {
          this.$emit("success");

          this.onRefresh();
        })
        .catch((err) => {
          this.loading = false;

          this.$store.commit("toasts.push", {
            title: err?.response?.data?.message,
            message: this.lodash.flattenDeep(Object.values(err?.response?.data?.errors || {})).join("\n"),
            type: "danger",
          });
        });
    },
    onChangeQuantity(new_quantity, row) {
      let matched_item_idx = this.not_in_container_boxes.findIndex(
        (i) => i.box_lading_bill_id === row.box_lading_bill_id
      );

      if (matched_item_idx !== -1) {
        this.not_in_container_boxes[matched_item_idx].quantity = new_quantity;
      }
    },
    onExportCSV() {
      let rows = [
        [
          "SKU",
          this.$t("Lading bill"),
          this.$t("Order"),
          this.$t("Customer"),
          this.$t("Quantity not in container"),
          this.$t("Updated at"),
        ],
      ];

      this.not_in_container_boxes.forEach((row) => {
        rows.push([
          row.box_id,
          row.lading_bill_id,
          row.order_id,
          row.customer_name,
          row.quantity,
          moment(row.updated_at, "X").format("DD/MM/YYYY HH:mm"),
        ]);
      });

      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${this.invoice_id}-missed-boxes.csv`);
      document.body.appendChild(link); // Required for FF

      link.click();
    },
  },
};
</script>
