<template>
  <CRow class="mb-5">
    <CCol lg="3">
      <TAside>
        <CListGroup>
          <CListGroupItem>
            <TInputSimpleSearch
              placeholder="Search invoice"
              :value.sync="search"
            />
          </CListGroupItem>
          <CListGroupItem
            v-for="(invoice, index) in invoices"
            :key="`${index}${invoice.id}`"
            :color="invoice_id_selected == invoice.id ? 'primary' : 'secondary'"
            :class="[
              'p-0',
              {
                'list-group-item-success':
                  invoice.had_cut_off && !(invoice_id_selected == invoice.id),
              },
            ]"
          >
            <SCardInvoice
              class="cursor-pointer"
              @click.native="
                lodash.appendRouteQuery($router, {
                  query: { invoice_id: invoice.id },
                })
              "
              :invoice="invoice"
            />
          </CListGroupItem>
          <CListGroupItem v-if="!invoices.length">
            <TMessageNotFound />
          </CListGroupItem>
          <CListGroupItem v-if="list_fetching">
            <TSpinner :loading="list_fetching" />
          </CListGroupItem>
        </CListGroup>
      </TAside>
    </CCol>
    <CCol lg="9">
      <CRow v-if="invoice_id_selected">
        <!-- Card overview xuất kho -->
        <CCol col="12" v-if="invoice_selected">
          <CCard>
            <CCardHeader class="d-flex align-items-center flex-wrap">
              <TMessage content="SKUs in invoice">
                <template #suffix>
                  <span class="ml-1 font-weight-bold">{{
                    invoice_selected.name || "..."
                  }}</span>
                </template>
              </TMessage>
              <TSpinner :loading="detail_loading" />

              <div class="ml-auto">
                <SButtonStatusInvoice
                  :invoice="invoice_selected"
                  store="warehouse.invoices_in"
                />
                <TButtonRefresh
                  @click="refetchInvoice"
                  class="ml-2"
                  size=""
                  :options="{ disabled: detail_loading }"
                />
              </div>
            </CCardHeader>
            <CCardBody>
              <div>
                <CRow>
                  <CCol col="6">
                    <TMessage
                      content="Total number of boxes above expected"
                      class="h5"
                    >
                      <template #suffix
                        >: <b>{{ count_duplicate }}</b>
                      </template>
                    </TMessage>
                  </CCol>
                  <CCol col="6">
                    <TMessage content="Total number of boxes loaded" class="h5">
                      <template #suffix
                        >: <b>{{ count_incont }}</b>
                      </template>
                    </TMessage>
                  </CCol>
                </CRow>
              </div>
            </CCardBody>
          </CCard>
        </CCol>

        <!-- Card input nhập kho -->
        <CCol col="12">
          <CCard>
            <CCardBody>
              <SInputSku
                :disabled="invoice_selected.had_cut_off"
                @enter="enterPicker"
                placeholder="Add SKU or Pallet Id"
                style="margin-bottom: 0 !important"
              />
            </CCardBody>
            <CCardFooter>
              <div class="d-flex align-items-center justify-content-end">
                <TButtonQuickView
                  :options="{ content: 'Watch boxes was in container' }"
                  @click="showModalPickedBox = true"
                />

                <RemoveNotInContainerBox
                  @success="
                    $store.dispatch(
                      'warehouse.invoices_in.detail.fetch',
                      invoice_id_selected
                    )
                  "
                />

                <TButton
                  @click="showModalPickedBoxError = true"
                  color="danger"
                  variant="ghost"
                  size="sm"
                  icon="cil-magnifying-glass"
                  v-if="boxes_picked_error.length"
                >
                  <template #content>
                    <TMessage
                      class="ml-1"
                      content="Watch data was been error"
                    />
                    <CBadge
                      color="danger"
                      class="ml-1 d-flex align-items-center justify-content-center"
                    >
                      <span style="font-size: 12px">{{
                        boxes_picked_error.length
                      }}</span>
                    </CBadge>
                  </template>
                </TButton>

                <PickedBoxModal
                  :show.sync="showModalPickedBox"
                  :pickers="in_container_pickeds"
                  @focus-quantity:change="updateQuantityPicker"
                  @remove="removePicker"
                  referer_quantity_key="current_quantity_in_lading_bill"
                  :fetching="in_container_pickers_loading"
                  @remove-boxes="onRemoveBoxesInCont"
                  :removing="removing_boxes_incont"
                />

                <PickedBoxErrorModal
                  :show.sync="showModalPickedBoxError"
                  :pickers="boxes_picked_error"
                />
              </div>
            </CCardFooter>
          </CCard>
        </CCol>

        <!-- [8] Card hàng đợi nhập kho -->
        <CCol col="12" md="12" lg="6">
          <CCard style="background: #f9f9f9">
            <CCardHeader
              class="d-flex align-items-center justify-content-between"
            >
              <div class="d-flex align-items-center">
                <TMessage content="Pick in container">
                  <template #suffix>
                    <span class="ml-1 font-weight-bold">{{
                      invoice_selected.name || "..."
                    }}</span>
                  </template>
                </TMessage>
                <TSpinner :loading="in_container_pickers_loading" />
              </div>

              <TButton
                color="success"
                :messageOptions="{ bold: true }"
                icon="cil-data-transfer-down"
                :options="{
                  disabled: !pick_boxes.length || in_container_pickers_loading,
                }"
                @click="submitPickers"
                v-if="pick_boxes.length"
              >
                <template #content>
                  <TMessage content="Import" bold class="ml-2">
                    <template #suffix>
                      <span v-if="lodash.sumBy(pick_boxes, 'quantity')">
                        {{
                          `(${_.sumBy(pick_boxes, "quantity")} ${$t("boxes")})`
                        }}
                      </span>
                    </template>
                  </TMessage>
                </template>
              </TButton>
            </CCardHeader>
            <CCardBody class="p-3">
              <TButton
                v-if="pick_boxes.length"
                content="Remove all"
                variant="ghost"
                icon="cil-delete"
                class="float-right mr-2 mb-2"
                color="danger"
                @click="showModalConfirm = true"
              />
              <TModalConfirm
                :show.sync="showModalConfirm"
                @click-confirm="
                  onlDeselectAll();
                  showModalConfirm = false;
                "
              />
              <div>
                <TMessageNoData content="No SKU" v-if="!pick_boxes.length" />
                <div v-else>
                  <div
                    class="my-1 mx-2"
                    v-for="(picker, index) in pick_boxes"
                    :key="`${picker.id} - ${index}`"
                  >
                    <SCardSkuContainerPicker
                      v-if="lodash.has(picker, 'box')"
                      :box="picker.box"
                      class="w-100"
                      :focus-quantity="picker.quantity"
                      :focus-quantity-ediable="true"
                      @focus-quantity:change="
                        updateQuantityPicker(picker, $event)
                      "
                      :removable="true"
                      @updated="updatePalletPicker(picker, $event.pallet_id)"
                      @remove="removePicker(picker, $event)"
                      :removing="picker.removing"
                      :updating="picker.updating"
                      :background="getBoxBackgroundColorPicking(picker)"
                      showPallet
                      noItems
                      focusQuantityDescription="Quantity in cont"
                      mainQuantityDescription="Expected quantity"
                      :showDateTime="!picker.picking"
                      :noConfirmOnRemove="picker.picking"
                      :showWeight="false"
                      :showSfa="false"
                    >
                      <template #picked_by_pallet v-if="!picker.picking">
                        <CBadge
                          v-if="picker.pallet_id"
                          color="success"
                          class="my-auto"
                        >
                          {{ picker.pallet_id }}
                        </CBadge>
                      </template>

                      <template #main-quantity>
                        <TMessageNumber
                          :value="getTotalQuantityInLadingBills(picker)"
                        />
                      </template>
                    </SCardSkuContainerPicker>
                  </div>
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
        <!-- [4] Card chi tiết các thông tin thùng đã lên cont -->
        <CCol col="6" md="6" lg="3">
          <CCard style="background: #f9f9f9">
            <CCardHeader class="d-flex align-items-center">
              <TMessage content="Loaded" bold />
              <div class="ml-auto">
                <TButtonSave
                  @click="onExportCSV(boxes_in_cont)"
                  :options="{ content: 'Export CSV' }"
                />
              </div>
            </CCardHeader>
            <CCardBody class="py-2 px-2">
              <TSpinner
                :loading="in_container_pickers_loading || detail_loading"
              />
              <TMessageNoData
                content="No SKU"
                v-if="!boxes_in_cont.length && !detail_loading"
              />
              <div v-else class="w-100 mt-2">
                <SCardSkuContainerPicker
                  v-for="box in boxes_in_cont"
                  :key="box.id"
                  :box="box"
                  class="w-100"
                  refresh
                  :focus-quantity="box.quantity_in_picker_in_cont"
                  :focus-quantity-title="$t('Quantity in cont')"
                  :background="getBoxCardBackground(box)"
                >
                  <template
                    #prepend-quantity
                    v-if="pickingBoxQuantityById(box.id)"
                  >
                    <div style="font-size: 20px; font-weight: 400">
                      <TMessageNumber
                        :value="pickingBoxQuantityById(box.id)"
                        :title="$t('Picking quantity')"
                      />
                    </div>
                  </template>

                  <template #main-quantity>
                    <TMessageNumber
                      :value="box.total_quantity_in_lading_bills"
                      :title="$t('Quantity in lading bill')"
                    />
                  </template>
                </SCardSkuContainerPicker>
              </div>
            </CCardBody>
          </CCard>
        </CCol>

        <!-- [5] Card chi tiết các thông tin thùng chưa lên cont-->
        <CCol col="6" md="6" lg="3">
          <CCard style="background: #f9f9f9">
            <CCardHeader class="d-flex align-items-center">
              <TMessage content="Remaining" bold />
              <div class="ml-auto">
                <TButtonSave
                  @click="onExportCSV(remaining_boxes_in_lading_bills)"
                  :options="{ content: 'Export CSV' }"
                />
              </div>
            </CCardHeader>
            <CCardBody class="py-2 px-2">
              <TSpinner
                :loading="in_container_pickers_loading || detail_loading"
              />

              <div
                class="d-flex ml-auto align-items-center"
                v-if="remaining_boxes_in_lading_bills.length && !detail_loading"
              >
                <TSwitch
                  :checked="sortByPallet"
                  @change="sortByPallet = !sortByPallet"
                />
                <TMessage content="Sort by pallet" bold class="ml-2 mt-1" />
              </div>
              <PalletBoxModal
                :show.sync="showModalPallet"
                :box="skuInLBSelected"
                @updated="refetchInvoice"
              />

              <TMessageNoData
                content="No SKU"
                v-if="
                  !remaining_boxes_in_lading_bills.length && !detail_loading
                "
              />

              <div v-if="sortByPallet">
                <div
                  v-for="(pivot_pallet, index) in boxes_sort_by_pallet"
                  :key="`${pivot_pallet.pallet_id}-${index}`"
                >
                  <div
                    v-if="pivot_pallet.pallet_id"
                    class="d-flex align-items-center mt-3"
                  >
                    <TLink
                      :content="pivot_pallet.pallet_id"
                      :to="
                        lodash.getReferenceLink(
                          'assemble-to-stock.pallet',
                          pivot_pallet.pallet_id
                        )
                      "
                      :messageOptions="{ bold: true }"
                      class="h4 m-0"
                    />
                    <SMessagePalletLocation
                      :location="pivot_pallet.pallet.location"
                      v-if="pivot_pallet.pallet.location"
                      color="success"
                      class="ml-2 h5 mb-1"
                    />
                  </div>
                  <TMessageNotFound
                    v-if="!pivot_pallet.pallet_id && pivot_pallet.boxes.length"
                    class="h3 mt-3"
                  />
                  <hr v-if="pivot_pallet.boxes.length" class="my-0" />
                  <div class="d-flex flex-wrap w-100">
                    <SCardSkuContainerPicker
                      v-for="box in pivot_pallet.boxes"
                      :key="box.box_id"
                      :box="box"
                      class="mt-1 mx-2 w-100"
                      refresh
                      :focus-quantity="box.quantity_in_container_picker"
                      :background="getBoxCardBackground(box)"
                      :focus-quantity-title="$t('Quantity in cont')"
                    >
                      <template
                        #prepend-quantity
                        v-if="
                          pickingBoxQuantityById(
                            box.id,
                            pivot_pallet.pallet_id
                          ) && pivot_pallet.pallet_id
                        "
                      >
                        <div style="font-size: 20px; font-weight: 400">
                          <TMessageNumber
                            :value="
                              pickingBoxQuantityById(
                                box.id,
                                pivot_pallet.pallet_id
                              )
                            "
                            :title="$t('Picking quantity')"
                          />
                        </div>
                      </template>
                      <template #main-quantity>
                        <TMessageNumber
                          :value="box.desired_quantity"
                          :title="$t('Quantity in pallets')"
                        />
                      </template>
                      <template #append-actions>
                        <CLink
                          class="ml-2 text-info"
                          title="Option"
                          @click="
                            showModalPallet = true;
                            skuInLBSelected = box;
                          "
                        >
                          <CIcon name="cisChartTable" width="16" />
                        </CLink>
                      </template>
                    </SCardSkuContainerPicker>
                  </div>
                </div>
              </div>

              <div v-else class="w-100 mt-2">
                <SCardSkuContainerPicker
                  v-for="box in remaining_boxes_in_lading_bills"
                  :key="box.id"
                  :box="box"
                  class="w-100"
                  refresh
                  :focus-quantity="box.quantity_in_picker_in_cont"
                  :focus-quantity-title="$t('Quantity in cont')"
                  :background="getBoxCardBackground(box)"
                >
                  <template
                    #prepend-quantity
                    v-if="pickingBoxQuantityById(box.id)"
                  >
                    <div style="font-size: 20px; font-weight: 400">
                      <TMessageNumber
                        :value="pickingBoxQuantityById(box.id)"
                        :title="$t('Picking quantity')"
                      />
                    </div>
                  </template>

                  <template #main-quantity>
                    <TMessageNumber
                      :value="box.total_quantity_in_lading_bills"
                      :title="$t('Quantity in lading bill')"
                    />
                  </template>
                </SCardSkuContainerPicker>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>
import audio from "../../mixins/audio";
import readDWSSKU from "../../mixins/readDWSSKU";
import searchable from "../../mixins/searchable";
import barcode from "../../mixins/barcode";
import PICK_IN_CONT from "../../store/pick_in_cont.local";
import warehouseScope from "@/core/services/tomoni/warehouse_scope.local";
import PalletBoxModal from "../../components/PalletBoxModal.vue";
import PickedBoxModal from "../../components/PickedBoxModal.vue";
import PickedBoxErrorModal from "../../components/PickedBoxErrorModal.vue";
import RemoveNotInContainerBox from "../../components/RemoveNotInContainerBox.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    PalletBoxModal,
    PickedBoxModal,
    PickedBoxErrorModal,
    RemoveNotInContainerBox,
  },
  mixins: [searchable, barcode, audio, readDWSSKU],
  data() {
    return {
      picking_boxes: [],
      in_api_process_boxes: [],
      sortByPallet: false,
      boxes_sort_by_pallet: [],
      showModalPallet: false,
      showModalPickedBox: false,
      skuInLBSelected: {},
      boxes_picked_error: [],
      showModalPickedBoxError: false,
      removing_boxes_incont: false,
      showModalConfirm: false,
    };
  },
  watch: {
    invoice_id_selected: {
      immediate: true,
      handler(id) {
        if (id) {
          this.$store
            .dispatch("warehouse.invoices_in.detail.fetch", id)
            .then(() => {
              if (this.$store.getters["printer.value"]?.readyState) {
                this.$store.dispatch(
                  "printer.send-message",
                  JSON.stringify({
                    type: "ReadDWS",
                    Command: id,
                  })
                );
              }
            })
            .catch(() => {
              this.$router.push("/warehouse/outbound/transfer-order");
            });
          this.sortByPallet = false;
          this.fetchInPickers();
          this.fetchPickingBoxes();
          this.boxes_picked_error = [];
        }
      },
    },
    boxes_in_lading_bills() {
      this.sortByPalletHandle();
    },
  },
  computed: {
    invoice_id_selected() {
      return this.$route.query.invoice_id;
    },
    invoice_selected() {
      let invoice = this.$store.getters["warehouse.invoices_in.detail"];
      if (!this.lodash.isEmpty(invoice))
        invoice.boxes_in_lading_bills = invoice?.boxes_in_lading_bills?.map(
          (box) => {
            const quantity = this.lodash.sumBy(
              invoice.in_pickers.filter((x) => x.box_id == box.id),
              "quantity"
            );
            return {
              ...box,
              quantity_in_picker_in_cont: quantity,
            };
          }
        );

      return invoice;
    },
    ...mapGetters({
      invoices: "warehouse.invoices_in.list",
      lading_bills: "warehouse.lading_bills.list",
      list_fetching: "warehouse.invoices_in.fetching",
      detail_loading: "warehouse.invoices_in.detail.loading",
      invoices_creating: "warehouse.invoices_in.creating",
      in_container_pickers: "warehouse.in_container_pickers.list",
      in_container_pickers_loading: "warehouse.in_container_pickers.loading",
    }),
    in_container_pickeds() {
      return this.$store.getters["warehouse.in_container_pickers.list"].map(
        (i) => {
          return {
            ...i,
            current_quantity_in_lading_bill:
              this.getCurrentQuantityInLadingBill(i.box_id),
          };
        }
      );
    },
    pick_boxes() {
      return [...this.picking_boxes, ...this.in_api_process_boxes];
    },
    boxes_in_lading_bills() {
      return this?.invoice_selected?.boxes_in_lading_bills || [];
    },
    boxes_in_cont() {
      return (
        this.invoice_selected?.boxes_in_lading_bills?.filter(
          (x) =>
            x.quantity_in_picker_in_cont >= x.total_quantity_in_lading_bills
        ) || []
      );
    },
    remaining_boxes_in_lading_bills() {
      return (
        this.invoice_selected?.boxes_in_lading_bills?.filter(
          (x) => x.quantity_in_picker_in_cont < x.total_quantity_in_lading_bills
        ) || []
      );
    },
    count_actual_boxes_in_lading_bills() {
      return this.invoice_selected?.boxes_in_lading_bills?.filter(
        (x) => x.quantity_in_picker_in_cont == x.total_quantity_in_lading_bills
      )?.length;
    },
    count_remaining_boxes_in_lading_bills() {
      return this.invoice_selected?.boxes_in_lading_bills?.filter(
        (x) => x.quantity_in_picker_in_cont < x.total_quantity_in_lading_bills
      )?.length;
    },
    count_boxes_in_lading_bills() {
      return this.invoice_selected?.boxes_in_lading_bills?.length || 0;
    },
    count_duplicate() {
      return (
        this.lodash.sumBy(
          this.invoice_selected?.boxes_in_lading_bills,
          "total_quantity_in_lading_bills"
        ) || 0
      );
    },
    count_incont() {
      return (
        this.lodash.sumBy(this.invoice_selected?.in_pickers, "quantity") || 0
      );
    },
  },
  methods: {
    handleReadDWSSKU(e) {
      const data = JSON.parse(e.data);
      if (data.type == "DWS_SKU") {
        this.enterPicker(data.box_id);
      }
    },
    getCurrentQuantityInLadingBill(box_id) {
      return (
        (this.invoice_selected?.boxes_in_lading_bills || []).find(
          (i) => i.id === box_id
        )?.total_quantity_in_lading_bills || 0
      );
    },
    pickingBoxQuantityById(box_id, pallet_id) {
      if (pallet_id)
        return (
          this.pick_boxes.find(
            (x) => x.id === box_id && x.box.pallet_id === pallet_id
          )?.quantity || 0
        );
      return this.pick_boxes
        .filter((x) => x.id === box_id)
        .reduce((p, c) => p + c.quantity, 0);
    },
    pickedBoxQuantityById(box_id, pallet_id) {
      let matched_boxes = (this.in_container_pickers || []).filter(
        (i) => i.box_id === box_id
      );

      if (pallet_id)
        return matched_boxes
          .filter((i) => i.pallet_id === pallet_id)
          .reduce((p, c) => p + c.quantity, 0);

      return matched_boxes.reduce((p, c) => p + c.quantity, 0);
    },
    changeTab(tab) {
      this.$router.push({ query: { tab: tab } });
    },
    isPalletIdStr(pallet) {
      if (this.lodash.isString(pallet))
        return pallet.charAt(0).toUpperCase() == "P";
      return pallet?.id.charAt(0).toUpperCase() == "P";
    },
    fetchInPickers() {
      return this.$store.dispatch(
        "warehouse.in_container_pickers.apply-query",
        {
          "filter[container_id]": this.invoice_id_selected,
        }
      );
    },
    refetchInvoice() {
      return this.$store.dispatch("warehouse.invoices_in.detail.refresh");
    },
    fetchPickingBoxes() {
      this.picking_boxes =
        PICK_IN_CONT.get(this.invoice_id_selected)?.filter((x) => x.box) || [];
    },
    pickBarcode(e) {
      const code = this.press(e);
      if (code) {
        this.enterPicker(code);
      }
    },
    onlDeselectAll() {
      PICK_IN_CONT.remove(this.invoice_id_selected);
      this.fetchPickingBoxes();
    },
    onEnterDataError(k, type) {
      this.playFail();
      this.boxes_picked_error.push({ data: k, type });
    },
    enterPicker(picker_str) {
      if (
        this.invoice_selected.had_cut_off ||
        !this.invoice_id_selected ||
        this.showModalPallet ||
        this.lodash.isEmpty(picker_str)
      ) {
        this.$store.commit("toasts.push", {
          title: "Picker is not ready",
          message: "Picker is not ready to work",
          type: "danger",
        });
        this.onEnterDataError(picker_str, "invalid-sku-in-lading-bill");

        return;
      }

      if (this.isPalletIdStr(picker_str)) {
        const picking = new Promise((resolve, reject) => {
          this.$tomoni.warehouse.pallets
            .all({
              include: "pivotBoxes",
              "filter[id]": picker_str,
            })
            .then((res) => res.data?.data?.[0])
            // .dispatch("warehouse.pallets.detail.fetch", picker_str)
            .then((data) => {
              if (!data?.id) {
                this.$store.commit("toasts.push", {
                  title: this.$t("Invalid pallet"),
                  message: "There is no valid SKU in the pallet",
                  type: "danger",
                });

                reject({ p: picker_str, t: "fetch-data-fail" });
                return;
              }

              if (data.pivot_boxes.length) {
                data.pivot_boxes.forEach((pivot_box, idx) => {
                  const matched_box_lb =
                    this.invoice_selected.boxes_in_lading_bills.find(
                      (i) => i.id === pivot_box.box_id
                    );

                  if (matched_box_lb) {
                    let picking_quantity = this.pickingBoxQuantityById(
                      pivot_box.box_id
                    );
                    let picked_quantity = this.pickedBoxQuantityById(
                      pivot_box.box_id
                    );
                    let quantity = Math.min(
                      pivot_box.current_quantity,
                      matched_box_lb.total_quantity_in_lading_bills -
                        picking_quantity -
                        picked_quantity
                    );

                    if (quantity > 0) {
                      this.onEnterBox(pivot_box.box_id, picker_str, quantity);
                    } else {
                      this.$store.commit("toasts.push", {
                        message: `[${idx + 1}] ${
                          pivot_box.box_id
                        } - Current quantity of box in pallet is smaller than 0`,
                        type: "danger",
                      });
                      reject({ p: pivot_box.box_id, t: "empty-pallet-box" });
                    }
                  } else {
                    this.$store.commit("toasts.push", {
                      message: `[${idx + 1}] ${
                        pivot_box.box_id
                      } - Invalid pivot box`,
                      type: "danger",
                    });
                    reject({ p: pivot_box.box_id, t: "invalid-pivot-box" });
                  }
                });
              } else {
                this.$store.commit("toasts.push", {
                  title: this.$t("Invalid pallet"),
                  message: "There is no valid SKU in the pallet",
                  type: "danger",
                });
                reject({ p: picker_str, t: "empty-pallet" });
              }
            })
            .catch(() => {
              this.$store.commit("toasts.push", {
                title: this.$t("Invalid pallet"),
                message: "Fetch pallet data fail",
                type: "danger",
              });
              reject({ p: picker_str, t: "fetch-data-fail" });
            });
        });

        picking.catch(({ p, t }) => {
          this.onEnterDataError(p, t);
        });
      } else {
        this.onEnterBox(picker_str);
      }
    },
    getActivePivotPallet(picker, pallet_id) {
      if (pallet_id)
        return (picker?.box?.pivot_pallets || []).find(
          (i) => i.pallet_id === pallet_id
        );

      return (picker?.box?.pivot_pallets || []).find(
        (i) => i.pallet_id === picker?.pallet_id
      );
    },
    getAvailablePicking(box_id, pallet_id) {
      /**
       * Nếu có pallet_id thì check xem cái picker khớp box_id và pallet_id có available k
       * Nếu k có palelt_id thì lấy ra picker đầu tiên available trong list picking_boxes
       */

      if (pallet_id) {
        let current_picking = this.getCurrentPicking(box_id, pallet_id);
        let active_pivot_pallet = this.getActivePivotPallet(
          current_picking,
          pallet_id
        );
        let current_api_process = this.in_api_process_boxes.find(
          (t) => t.id === box_id && t.pallet_id === pallet_id
        );
        let is_picker_available =
          (active_pivot_pallet?.current_quantity || 0) >
          (current_picking?.quantity || 0) +
            (current_api_process?.quantity || 0);

        return is_picker_available ? current_picking : null;
      }

      return this.picking_boxes.filter((p) => {
        let current_api_process = this.in_api_process_boxes.find(
          (t) => t.id === box_id && t.pallet_id === p?.pallet_id
        );

        let active_pivot_pallet = this.getActivePivotPallet(p);
        let is_picker_available =
          active_pivot_pallet.current_quantity >
          p.quantity + Number(current_api_process?.quantity || 0);

        return p.box.id === box_id && is_picker_available;
      })?.[0];
    },
    getAvailablePivotPallets(sku) {
      return (
        this.invoice_selected.boxes_in_lading_bills.find((b) => b.id === sku)
          ?.pivot_pallets || []
      ).filter((pivot_pallet) => {
        let matched_pick_box_quantity = this.pick_boxes
          .filter(
            (t) => t.id === sku && t.pallet_id === pivot_pallet?.pallet_id
          )
          .reduce((p, c) => p + c.quantity, 0);

        return 1 + matched_pick_box_quantity <= pivot_pallet.current_quantity;
      });
    },
    getCurrentPicking(box_id, pallet_id) {
      return this.picking_boxes.find(
        (p) => p?.box?.pallet_id === pallet_id && p.id === box_id
      );
    },
    onValidateLadingBillBoxQuantity(lb_sku_obj, pallet_id, quantity = 1) {
      const quantity_in_picking_and_processing = this.pick_boxes
        .filter((p) => {
          return p.box.id === lb_sku_obj.id;
        })
        .reduce((p, c) => p + c.quantity, 0);

      // Nếu tổng số lượng box đang pick + số lượng box đã lên coontainer lớn hơn số lượng box trong lading_bill thì báo lỗi
      let is_over_lading_bill_box_quantity =
        quantity_in_picking_and_processing +
          lb_sku_obj?.quantity_in_picker_in_cont +
          quantity >
        lb_sku_obj?.total_quantity_in_lading_bills;

      if (is_over_lading_bill_box_quantity) {
        this.$store.commit("toasts.push", {
          title: this.$t("Invalid SKU") + ": " + lb_sku_obj.id,
          message: "Over lading bill quantity",
          type: "danger",
        });

        this.onEnterDataError(lb_sku_obj.id, "over-quantity-picker");
      }

      return !is_over_lading_bill_box_quantity;
    },
    onValidatePalletBoxQuantity(lb_sku_obj, pallet_id, next_quantity = 1) {
      let current_picking_pivot_pallet = lb_sku_obj.pivot_pallets.find(
        (i) => i.pallet_id === pallet_id
      );
      const quantity_in_picking_and_processing = this.pick_boxes
        .filter((p) => {
          return p.box.id === lb_sku_obj.id && p.pallet_id === pallet_id;
        })
        .reduce((p, c) => p + c.quantity, 0);

      let is_over_pallet_box_quantity =
        next_quantity + quantity_in_picking_and_processing >
        this.lodash.get(current_picking_pivot_pallet, "current_quantity", 0);

      if (is_over_pallet_box_quantity) {
        this.$store.commit("toasts.push", {
          title: this.$t("Invalid SKU") + ": " + lb_sku_obj.id,
          message: "Over pallet quantity",
          type: "danger",
        });
        this.onEnterDataError(lb_sku_obj?.id, "over-pallet-quantity");
      }

      return !is_over_pallet_box_quantity;
    },
    onJoinPicking(picker_obj) {
      PICK_IN_CONT.set(picker_obj, this.invoice_id_selected);
      this.playSuccess();
      this.fetchPickingBoxes();
    },
    onEnterBox(sku, pallet_id, default_quantity = 1) {
      /**
       * 1. Validate sku exist in lading_bill
       * 2. Tìm available picking
       * 3. Nếu không tìm thấy sku trong lading_bills thì báo lỗi
       * 4. Nếu pallet_id không được truyền vào, thì lấy từ available_picking hoặc từ list this.invoice_selected.boxes_in_lading_bills (this.getAvailablePivotPallets(sku)?.[0]?.id)
       * 5. Nếu sau bước 4 mà pallet_id vẫn không có, thì báo lỗi --> Không tồn tại SKU trên bất kì pallet nào
       * 6. Tính số lượng picking mới = số lượng ở cái pick sẽ nhảy vào + default_quantity
       * 7. Validate số lượng ở lading-bill
       * 8. Validate số lượng ở pallet
       * 9. Nếu có available_picking tồn tại ==> nhảy vào
       * 10. Nếu không có available_picking thì tạo picking mới
       */

      let lb_sku_obj = this.invoice_selected.boxes_in_lading_bills.find(
        (b) => b.id === sku
      );
      let available_picking = this.getAvailablePicking(sku, pallet_id);

      if (!lb_sku_obj) {
        this.$store.commit("toasts.push", {
          title: this.$t("Invalid SKU") + ": " + sku,
          message: "SKU not exist in lading bill",
          type: "danger",
        });
        this.onEnterDataError(sku, "sku-not-exist-in-lading-bill");

        return;
      }

      if (!pallet_id && available_picking)
        pallet_id = available_picking.pallet_id;
      else if (!pallet_id)
        pallet_id = this.getAvailablePivotPallets(sku)?.[0]?.pallet_id;

      if (!pallet_id) {
        this.$store.commit("toasts.push", {
          title: this.$t("Invalid SKU") + ": " + sku,
          message: "SKU not exist in pallets",
          type: "danger",
        });
        this.onEnterDataError(sku, "sku-not-exist-in-pallets");

        return;
      }

      let next_quantity = (available_picking?.quantity || 0) + default_quantity;

      if (
        !this.onValidateLadingBillBoxQuantity(
          lb_sku_obj,
          pallet_id,
          default_quantity
        )
      )
        return;
      if (
        !this.onValidatePalletBoxQuantity(
          lb_sku_obj,
          pallet_id,
          default_quantity
        )
      )
        return; // Nếu pallet_id không nằm trong available list, thì nó sẽ bị chặn bởi rule này

      if (available_picking)
        this.onJoinPicking({ ...available_picking, quantity: next_quantity });
      else {
        this.onJoinPicking({
          id: lb_sku_obj.id,
          quantity: next_quantity,
          pallet_id,
          box: {
            ...lb_sku_obj,
            pallet_id,
          },
          picking: true,
        });
      }
    },
    triggerSearch(value) {
      this.$store.commit("warehouse.invoices_in.push-query", {
        "filter[from_area_id]": warehouseScope.get(),
      });
      if (value || value == "") {
        this.$store.dispatch("warehouse.invoices_in.apply-query", {
          "filter[name]": value,
        });
      } else this.$store.dispatch("warehouse.invoices_in.fetch.if-first-time");
    },
    updatePalletPicker(picker, pallet_id) {
      if (picker.picking) {
        let current_api_process = this.in_api_process_boxes.find(
          (p) => p.box.id === picker.id && p.box.pallet_id === pallet_id
        );

        let current_picking_pivot_pallet = this.getActivePivotPallet({
          ...picker,
          box: { ...picker.box, pallet_id },
        });

        if (
          picker.quantity >
          current_picking_pivot_pallet.current_quantity -
            this.lodash.get(current_api_process, "quantity", 0)
        ) {
          this.onEnterDataError(
            picker?.id,
            "change-pallet-but-target-quantity-not-enough"
          );
          this.$store.commit("toasts.push", {
            title: "SKU" + ": " + picker.id,
            message:
              this.$t("The quantity cannot be more than") +
              " " +
              (current_picking_pivot_pallet.current_quantity -
                this.lodash.get(current_api_process, "quantity", 0)),
            type: "danger",
          });
          return;
        } else {
          PICK_IN_CONT.set(
            { ...picker, box: { ...picker.box, pallet_id } },
            this.invoice_id_selected
          );
          this.fetchPickingBoxes();
          return;
        }
      }
    },
    updateQuantityPicker(picker, quantity) {
      if (picker.picking) {
        let box = this.invoice_selected.boxes_in_lading_bills.find(
          (b) => b.id === picker.id
        );

        // Nếu số lượng + số lượng đã lên cont của box > Số lượng box trong lading_bills
        if (
          quantity + box?.quantity_in_picker_in_cont >
          box.total_quantity_in_lading_bills
        ) {
          this.onEnterDataError(
            picker?.id,
            "over-quantity-lading-bill-on-update"
          );
          this.$store.commit("toasts.push", {
            title: "SKU" + ": " + picker.id,
            message:
              this.$t("The quantity cannot be more than") +
              " " +
              (box?.total_quantity_in_lading_bills -
                box?.quantity_in_picker_in_cont),
            type: "danger",
          });
          return;
        }

        let current_api_process = this.in_api_process_boxes.find(
          (p) =>
            p.box.id === picker.id && p.box.pallet_id === picker?.box?.pallet_id
        );

        // Nếu số lượng + số lượng api process > Số lượng thùng trên pallet
        let current_picking_pivot_pallet = this.getActivePivotPallet(picker);
        let is_over_pallet_box_quantity =
          quantity + this.lodash.get(current_api_process, "quantity", 0) >
          current_picking_pivot_pallet.current_quantity;

        if (is_over_pallet_box_quantity) {
          this.onEnterDataError(picker?.id, "over-pallet-quantity");
          this.$store.commit("toasts.push", {
            title: "SKU" + ": " + picker.id,
            message:
              this.$t("The quantity cannot be more than") +
              " " +
              (current_picking_pivot_pallet.current_quantity -
                this.lodash.get(current_api_process, "quantity", 0)),
            type: "danger",
          });
          return;
        }

        PICK_IN_CONT.set({ ...picker, quantity }, this.invoice_id_selected);
        this.fetchPickingBoxes();
        return;
      }

      this.$store
        .dispatch("warehouse.in_container_pickers.update", {
          id: picker.id,
          attributes: { quantity },
        })
        .then(() => {
          this.refetchInvoice();
        });
    },
    removePicker(picker) {
      if (picker.picking) {
        PICK_IN_CONT.removePicker(this.invoice_id_selected, picker);
        this.fetchPickingBoxes();
        return;
      }

      this.$set(picker, "removing", true);
      this.$store
        .dispatch("warehouse.in_container_pickers.delete", picker.id)
        .finally(() => {
          this.$set(picker, "removing", false);
          this.refetchInvoice();
        });
    },
    getBoxCardBackground(box) {
      let quantity_in_cont =
        box?.quantity_in_container_picker ||
        box?.quantity_in_picker_in_cont ||
        0;

      if (quantity_in_cont > box.total_quantity_in_lading_bills)
        return "#ffa39e";
      if (
        quantity_in_cont === box.total_quantity_in_lading_bills ||
        quantity_in_cont === box.desired_quantity
      )
        return "#d9f7be";

      return "#fff";
    },
    getBoxBackgroundColorPicking(picker) {
      if (!picker.quantity) return "#ffa39e";

      const box = this.invoice_selected.boxes_in_lading_bills?.find(
        (b) => b.id === picker.box.id
      );
      const total_quantity_in_lading_bills =
        this.getTotalQuantityInLadingBills(picker);

      if (
        picker.quantity + box?.quantity_in_picker_in_cont ===
        total_quantity_in_lading_bills
      ) {
        return "#d9f7be";
      }

      return "#fff";
    },
    getTotalQuantityInLadingBills(picker) {
      if (!this.invoice_selected.boxes_in_lading_bills) {
        return NaN;
      }
      const box = this.invoice_selected.boxes_in_lading_bills.find(
        (b) => b.id === picker.box.id
      );

      if (!box) {
        return NaN;
      }
      return box.total_quantity_in_lading_bills;
    },
    submitPickers() {
      let data = {
        container_id: this.invoice_selected.id,
      };
      this.in_api_process_boxes = [
        ...this.in_api_process_boxes,
        ...this.picking_boxes,
      ];
      this.picking_boxes = [];
      this.in_api_process_boxes.forEach((picker) => {
        PICK_IN_CONT.removePicker(this.invoice_id_selected, picker);
      });

      this.in_api_process_boxes.forEach((object, index) => {
        const item = {
          id: object.id,
          quantity: object.quantity,
          pallet_id: object.box.pallet_id,
        };
        const boxItem = this.lodash.mapKeys(item, (value, key) => {
          return `boxes[${index}][${key}]`;
        });
        data = { ...data, ...boxItem };
      });

      this.$store
        .dispatch(
          "warehouse.in_container_pickers.create",
          this.lodash.pickBy(data, this.lodash.identity)
        )
        .then(() => {
          this.fetchPickingBoxes();
          this.fetchInPickers()
            .then(() => {
              setTimeout(() => {
                this.refetchInvoice();
              }, 1500);
            })
            .finally(() => (this.in_api_process_boxes = []));
          this.sortByPalletHandle()
        })
        .catch(() => {
          this.fetchPickingBoxes();
          this.in_api_process_boxes = [];
        });
    },
    getDesiredPivotPallet(list, quantity) {
      let r = [];
      let c_quantity = 0;

      list.forEach((item) => {
        if (c_quantity >= quantity) return;

        r.push({
          ...item,
          desired_quantity: Math.min(
            item.current_quantity + item.quantity_in_container_picker,
            quantity - c_quantity
          ),
        });

        c_quantity += item.current_quantity + item.quantity_in_container_picker;
      });

      return r;
    },
    sortByPalletHandle() {
      // Danh sách pivot pallet
      let pivot_pallet_list = this.remaining_boxes_in_lading_bills.reduce(
        (p, c) => {
          let sorted_pivot_pallets = c?.pivot_pallets || [];
          sorted_pivot_pallets = this.getDesiredPivotPallet(
            sorted_pivot_pallets,
            c.total_quantity_in_lading_bills
          );

          return p.concat(
            sorted_pivot_pallets.map((i) => ({
              ...i,
              total_quantity_in_lading_bills: c.total_quantity_in_lading_bills,
              pivot_id: i.id,
              id: i.box_id,
            }))
          );
        },
        []
      );

      // Danh sách các thùng chưa lên hết pallet
      let not_enought_pallet_list = this.boxes_in_lading_bills
        .filter(
          (box) =>
            box.total_quantity_in_lading_bills > box.quantity_in_pallets &&
            box.quantity_in_pallets > 0
        )
        .map((i) => ({
          id: i.id,
          box_id: i.id,
          pallet_id: null,
          pallet: null,
          total_quantity_in_lading_bills: i.total_quantity_in_lading_bills,
          desired_quantity:
            i.total_quantity_in_lading_bills - i.quantity_in_pallets,
          quantity_in_container_picker: 0,
        }));

      // Danh sách các thùng không tồn tại pallet
      let missing_pallet_list = this.boxes_in_lading_bills
        .filter((box) => !box.pivot_pallets.length)
        .map((i) => ({
          id: i.id,
          box_id: i.id,
          pallet_id: null,
          pallet: null,
          total_quantity_in_lading_bills: i.total_quantity_in_lading_bills,
          desired_quantity: i.total_quantity_in_lading_bills,
          quantity_in_container_picker: i.quantity_in_picker_in_cont,
        }));

      // Group pallet và tạo list
      pivot_pallet_list = this.lodash.groupBy(pivot_pallet_list, "pallet_id");
      pivot_pallet_list = Object.entries(pivot_pallet_list).reduce(
        (r, [pallet_id, pivot_boxes]) => {
          if (!pivot_boxes.length) return r;

          r.push({
            pallet_id,
            pallet: pivot_boxes[0].pallet,
            boxes: pivot_boxes,
          });

          return r;
        },
        []
      );

      this.boxes_sort_by_pallet = [
        ...pivot_pallet_list,
        {
          id: null,
          boxes: [...missing_pallet_list, ...not_enought_pallet_list],
        },
      ];
    },
    onExportCSV(data) {
      let rows = [];

      // Xuất CSV khi ở trạng thái sort theo pallet
      if (this.sortByPallet) {
        rows = [
          [
            "Index",
            "Pallet",
            "SKU",
            "Quantity in pallet",
            "Quantity in lading bill",
            "Quantity picked in container",
          ],
        ];

        this.boxes_sort_by_pallet.forEach((pallet, idx) => {
          (pallet?.boxes || []).forEach((box, b_idx) => {
            rows.push([
              (idx + 1) * (b_idx + 1),
              pallet?.pallet_id || "",
              box.id,
              box.quantity,
              box.total_quantity_in_lading_bills,
              box.quantity_in_container_picker,
            ]);
          });
        });
      } else {
        // Xuất CSV khi ở trạng thái bình thường
        rows = [
          [
            "Index",
            "SKU",
            "Quantity picked in container",
            "Quantity in lading bill",
          ],
        ];

        data.forEach((box_lb, idx) => {
          rows.push([
            idx + 1,
            box_lb.id,
            box_lb.quantity_in_picker_in_cont,
            box_lb.total_quantity_in_lading_bills,
          ]);
        });
      }

      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "my_data.csv");
      document.body.appendChild(link); // Required for FF

      link.click();
    },
    onRemoveBoxesInCont(items) {
      if (!items.length) return;

      this.removing_boxes_incont = true;

      let items_uniq_by_box_id = this.lodash.uniqBy(items, "box_id");

      this.$tomoni.warehouse.containers
        .removePickInContainers(this.invoice_id_selected, {
          ...items_uniq_by_box_id.reduce((p, c, idx) => {
            p[`box_ids[${idx}]`] = c.box_id;
            return p;
          }, {}),
        })
        .then(() => {
          this.$store.commit("toasts.push", {
            message: this.$t("Remove successfully"),
            type: "success",
          });

          this.fetchInPickers();
          this.refetchInvoice();
        })
        .catch((err) => {
          this.$store.commit("toasts.push", {
            message: err.response.data.message,
            type: "danger",
          });
        })
        .finally(() => {
          this.removing_boxes_incont = false;
        });
    },
  },
};
</script>
